<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="250px"
          max-width="250px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/illustration-reset-password-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Réinitialiser le mot de passe 🔒
                  </p>
                  <p class="mb-2">
                    Redéfinissez le mot de passe
                  </p>
                </v-card-text>
                <v-alert
                  v-model="isAlertVisible"
                  :color="isErrorMessage ? 'error' : 'success'"
                  text
                  transition="slide-y-transition"
                >
                  {{ message }}
                </v-alert>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="resetPassword"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="example@example.com"
                      hide-details="auto"
                      class="mb-3"
                      :rules="[rules.login_required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="code"
                      outlined
                      label="Code"
                      placeholder="Code"
                      hide-details="auto"
                      class="mb-3"
                      :rules="[rules.code_required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Nouveau mot de passe"
                      placeholder="············"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline "
                      hide-details="auto"
                      class="mb-3"
                      :rules="[rules.password_required]"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      label="Confirmez le mot de passe"
                      placeholder="············"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      :rules="[rules.password_confirmation_required]"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      class="mt-4"
                      type="submit"
                      :disabled="isDialogVisible"
                      :loading="isDialogVisible"
                    >
                      Définir
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center mt-2">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Retour à la page de connexion</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <loader-dialog :is-dialog-visible="isDialogVisible" />
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'

export default {
  name: 'ResetPassword',
  components: { LoaderDialog },
  setup() {
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const email = ref('')
    const code = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const form = ref(null)
    const { router } = useRouter()
    const isAlertVisible = ref(true)
    const isErrorMessage = ref(false)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const rules = {
      password_required: true,
      password_confirmation_required: true,
      code_required: true,
      login_required: true,
    }

    const initValues = () => {
      message.value = store.state.user.resp_message
      email.value = store.state.user.email
    }

    onMounted(initValues)

    const resetPassword = () => {
      form.value.validate()
      isAlertVisible.value = false
      isDialogVisible.value = true
      store.dispatch('user/resetPass', {
        login: email.value,
        code: code.value,
        password: password.value,
        password_confirmation: confirmPassword.value,
      }).then(() => {
        isDialogVisible.value = false
        router.push({ name: 'auth-login' })
      }).catch(error => {
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.password_required = error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : true
          rules.password_confirmation_required = error.response.data.errors.password_confirmation !== undefined ? error.response.data.errors.password_confirmation[0] : true
          rules.code_required = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
          rules.login_required = error.response.data.errors.login !== undefined ? error.response.data.errors.login[0] : true
        }
        isErrorMessage.value = true
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
    }

    return {
      errors,
      isErrorMessage,
      isDialogVisible,
      isAlertVisible,
      message,
      email,
      code,
      resetPassword,
      form,
      rules,
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
